import useAppContext from "../../hooks/useAppContext"
import Lup from './Lup';
import Poev from './Poev';

function WorkTable() {
  const {
    dataGame
  } = useAppContext()
  if(dataGame.name === 'algoritmo'){
    return <Poev/>
  }
  if(dataGame.name === 'LUP'){
    return <Lup/>
  }
  return <div>null</div>
}

export default WorkTable
