import useAppContext from '../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'
import {
  ContainerData,
  ContainerMiddle,
  ContainerPoev,
  ItemP,
  ContainerParentsLup,
  ContainerButton,
} from './index.components'
import { useEffect, useState } from 'react'
import interactjs from 'interactjs'
import { Button } from 'baseui/button'
import { PanelType } from '../../constants/app-options'
import { toaster } from 'baseui/toast'
import services from '../../services/games'
import domtoimage from 'dom-to-image';

function Lup() {
  const { dataUser, dataGame, activePanel, setIncorrectTask, setActivePanel, setImage64, setCompletTast, stepActive } =
    useAppContext()
  let valorReturn
  const [carga, setCarga] = useState(false)

  const [listStep, setList] = useState([])

  const setBoxSteps = (items: number) => {
    const listTemp = []
    for (let index = 0; index < items; index++) {
      interactjs(`.dropzone${index}`).dropzone({
        accept: null,
        overlap: 1,
        ondrop: function (event) {
          // cuando está en el recuardo sin hacer drag
          const elementDrag: HTMLElement = event.target
          elementDrag.classList.remove('activeDrag')
        },
        ondragleave: function (event) {
          // cuando sale del recuadro
          const elementDrag: HTMLElement = event.target,
            elementChildren: HTMLElement = event.relatedTarget
          elementDrag.classList.remove('activeDrag')
          elementChildren.removeAttribute('position')
          elementChildren.removeAttribute('parent')
          const elements = document.querySelectorAll(`div[position="${index + 1}"]`)
          if (elements.length > 1) {
            elementDrag.classList.add('more')
          } else {
            elementDrag.classList.remove('more')
          }
        },
        ondragenter: function (event) {
          // cuando ingresa al recuadro
          const elementDrag: HTMLElement = event.target,
            elementChildren: HTMLElement = event.relatedTarget
          elementChildren.setAttribute('position', (index + 1).toString())
          elementChildren.setAttribute('parent', `.dropzone${index}`)
          elementDrag.setAttribute('isActive', 'true')
          elementDrag.classList.add('activeDrag')
          elementDrag.classList.remove('border-error')
          const elements = document.querySelectorAll(`div[position="${index + 1}"]`)
          if (elements.length > 1) {
            elementDrag.classList.add('more')
          } else {
            elementDrag.classList.remove('more')
          }
        },
      })
      listTemp.push(index)
    }
    setList(listTemp)
  }

  useEffect(() => {
    if (dataGame.numberStepGame) {
      setBoxSteps(dataGame.numberStepGame)
    }
  }, [dataGame])

  const nextStep = async () => {
    console.log(stepActive, "active step")
    valorReturn = dataGame.name==='LUP' ? 5 : 4
    if (stepActive === valorReturn) {
      return
    }

    if(dataGame.name === 'LUP'){
      if(dataUser.isComplete){
        if (dataGame.activoTipoLup) {
          setActivePanel('Images' as PanelType)
        }else {
          if(activePanel === 'TipoLup' as any){
            toaster.negative('Selecciona una opcion', {})
          }else{
            setActivePanel('TipoLup' as PanelType)
          }
          return
         // setDataGame({...dataGame, activoTipoLup: true}) 
        }
      }else{
        toaster.negative('Falta tu información personal', {})
        return
      }
    }else{
      if (activePanel === 'DataUser') {
        if (dataUser.isComplete) {
          setActivePanel('Images' as PanelType)
        } else {
          toaster.negative('Falta tu información personal', {})
        }
        return
      }
    }
    setCarga(true)
    const items = document.querySelectorAll('.drag-drop'),
      mapeo = []
    items.forEach(item => {
      mapeo.push({
        id: item.getAttribute('fb-id'),
        position: parseInt(item.getAttribute('position') || '0'),
      })
    })

    let sendMapeo = mapeo.filter(mape => mape.position !== 0)
    if(sendMapeo.length > 0){
      const { validate, lupConteoMalas } = await services.validateGame(sendMapeo, 'LUP') 
      console.log(mapeo, "este es el mapeo")
      if (!validate) {
        setIncorrectTask(true)
        setCarga(false)
  
        // ponemos bordes alas incorrectas
        console.log("ponemos bordes alas incorrectas", document.querySelectorAll(".dropzone"))
  
        if (lupConteoMalas.length > 0) {
  
          lupConteoMalas.forEach(el => {
  
            document.querySelector(`.dropzone[data-key="${el.position}"]`).classList.add("border-error")
  
          })
  
        }
  
        return
      }
  
      //items que tengas dropzone
      const itemDropZone = document.querySelectorAll('.drag-drop[parent]')
  
      itemDropZone.forEach((ele: HTMLElement) => {
        const parent = document.querySelector(ele.getAttribute('parent'))
        const clonImage = ele.querySelector('img')
        clonImage.classList.add('activeImageSC')
        parent.appendChild(clonImage)
        ele.style.position = 'relative'
        ele.style.top = 'auto'
        ele.style.left = 'auto'
        ele.style.zIndex = 'initial'
        ele.removeAttribute('isNew')
      })
      // const canvas = await html2canvas(document.querySelector('.parent2'), {
      //   allowTaint: true,
      //   useCORS: true,
      // })
  
      // console.log(canvas, "canvasss")
      // const croppedCanvas = document.createElement('canvas')
      // const croppedCanvasContext = croppedCanvas.getContext('2d')
      // init data
      console.log("inicio")
      // const cropPositionTop = 0
      // const cropPositionLeft = 0
      // const cropWidth = canvas.width
      // const cropHeight = canvas.height
      // croppedCanvas.width = cropWidth
      // croppedCanvas.height = cropHeight
      // croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)
      // const base64Image = croppedCanvas.toDataURL('image/png', 1)
      //
      const node = document.querySelector('.parent2');
      generateImages()
      const image = await domtoimage.toPng(node)
      await services.saveData(image, "", dataUser.fullname, dataGame.id, dataGame.name);
   
      setCarga(false)
      setImage64(image)
      setCompletTast(true)
      console.log("fin")
    }else{
      setCarga(false)
      return
    }
  }


  const generateImages  = () =>{
    document.querySelectorAll(".drag-drop").forEach((element:any)=> {
      let isNew : any = element.getAttribute("isnew")
      let position = element.getAttribute("position")
  
      if(isNew === "true"){
      
      document.querySelectorAll(".dropzone").forEach(dropzone=>{
  
          let key : any = dropzone.getAttribute("data-key");
  
           if(key === position){
               dropzone.querySelector("div").style.display="none"
               element.style.position="relative"
               element.style.top=0
               element.style.left=0
               element.style.right=0
               element.style.bottom=0
               dropzone.appendChild(element)
           }
          
      })
      }
      
  })
  }

  return (
    <>
    <ContainerPoev>
      <div style={{ width: '98.5%' }}>
        <ContainerMiddle style={{ boxShadow: activePanel === 'DataUser' ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : 'none' }} className="parent2" id="parent2">
          <H5
            paddingBottom={'10px'}
            margin={0}
            $style={{ textAlign: 'center', borderBottom: '1px solid #dddddd' }}
            color="primaryB"
          >
            {dataGame.name}
          </H5>
          <ContainerData>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Nombre
              </ParagraphSmall>
              <ItemP active={!!dataUser.fullname} title={dataUser?.fullname} />
            </div>
            {/* <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Correo
              </ParagraphSmall>
              <ItemP active={!!dataUser.email} title={dataUser?.email} />
            </div> */}
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Cargo
              </ParagraphSmall>
              <ItemP active={!!dataUser.post} title={dataUser?.post} />
            </div>
            {/* <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Empresa
              </ParagraphSmall>
              <ItemP active={!!dataUser.company} title={dataUser?.company} />
            </div> */}
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Planta
              </ParagraphSmall>
              <ItemP active={!!dataUser.plant} title={dataUser?.plant} />
            </div>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Fecha
              </ParagraphSmall>
              <ItemP active={!!dataUser.date} title={dataUser?.date} />
            </div>
          </ContainerData>
          <hr style={{ backgroundColor: '#ccc' }} />
          <ContainerParentsLup>
            <div style={{ gridColumn: '1 / 3', marginBottom: '10px' }}>
              <H5 paddingBottom={'10px'} margin={0} $style={{ textAlign: 'center' }} color="primaryA">
                {
                (dataGame?.gametype === 'calidad' && 'Cómo elegir paltas hass maduras') || 
                (dataGame?.gametype === 'operacion' && 'Cómo cocinar pasta') || 
                (dataGame?.gametype === 'mantencion' && 'Cómo reemplazar las pilas de un control remoto') || 
                (dataGame?.gametype === 'seguridad' && 'Cómo actuar ante un terremoto') ||
                (dataGame?.gametype === '' && '') 
                }
              </H5>
            </div>
            <div>
              <ParagraphSmall $style={{ fontWeight: 500, textAlign: 'center' }} color={'green'}>
                Correcto
              </ParagraphSmall>
            </div>
            <div>
              <ParagraphSmall $style={{ fontWeight: 500, textAlign: 'center' }} color={'red'}>
                Incorrecto
              </ParagraphSmall>
            </div>
            {listStep.map((item, key) => (
              <div
                key={`drop${key}`}
                className={`dropzone dropzone${key} isLup ${key % 2 === 0 ? 'green' : 'red'}`}
                data-key={key + 1}
              >
                {key % 2 === 0 ? <div className="check">&#10004;</div> : <div className="check">X</div>}
              </div>
            ))}
          </ContainerParentsLup>
        </ContainerMiddle>
      </div>
      {
          <div style={{ width: '12%', height: '100%' }}>
          <ContainerButton style={{ zIndex: stepActive === valorReturn ? '9999' : 1 }}>
            <Button isLoading={carga} onClick={nextStep}>
              { !dataGame.activoTipoLup ? 'Siguiente' : 'Enviar y revisar'}
            </Button>
          </ContainerButton>
          </div>
      }




    </ContainerPoev>
    {/* <button onClick={handlePruebaCaptura}>PRUEBITA</button>
     <div className="test">
      </div> */}
    </>
  )
}
export default Lup
