export interface iImages {
  /** URL de la imagen */
  url: string
  /** En POEV Y LUP si dicen los numeros pero en tarjetas solo una tarjeta será la buena por ende las demas son false */
  position?: number | boolean
  /** Sirve solo en POEV Y LUP */
  id?: string
  // variables para tarjetas
  /** Titulo de la tarjeta */
  title?: string
  /** Descripcion de la tarjeta */
  description?: string
  /** Tipo de carta en tarjetas */
  typeCard?: string
}
export interface iDataGame {
  activoTipoLup: boolean,
  name: string
  id: string
  nameExtend: string
  /** Descripcion del modal de inicio de juego */
  description: string
  /** Lista de imagenes, en tarjetas tiene diferente funcion */
  images: iImages[]
  /** Imagen que sale en el modal de inicio, para tarjetas estos serán reemplazados por images */
  imageRef?: string
  /** fondo del modal de inicio */
  color: string // 
  /** color de boton del modal de cuando inicia el juego*/
  buttonColor: string,
  /** Numeros de pasos solo LUP Y POEV */
  numberStepGame?: number,
  /** Numeros de pasos de  tutorial lup poev */
  numberStepTutorial: number
  /** Descripcion del juego en Tarjetas */
  descriptionGame?: string
  /** Imagen del caso en Tarjetas */
  imageCase?: string
  /** Descripcion inicial */
  descriptionInitial: string
  /** Nombre del juego */
  nameGame?: string,
  stepActual: number,
  gametype: string, 
  multipleImages: object
}

export interface iItemTutorial {
  title: string,
  description: string,
  buttonName: string
}

export const itemsTutorialLup: iItemTutorial[] = [
  {
    title: 'Instrucciones',
    description: "Para crear tu LUP debes completar los siguientes pasos",
    buttonName: 'Continuar'
  },
  {
    title: 'Identifica la LUP completando los datos',
    description: "Escribe tus datos personales, de la empresa y otros datos relevantes en esta columna",
    buttonName: 'Siguiente'
  },
  {
    title: 'Selecciona qué tipo de LUP quieres crear',
    description: "Tus opciones son Operación, Calidad, Mantención y Seguridad.",
    buttonName: 'Siguiente'
  },
  {
    title: 'Arrastra los elementos hacia el documento',
    description: "Luego encontrarás diversos elementos (correctos e incorrectos) en la galería. Selecciona solo los que consideres necesarios y ubícalos en el lugar correspondiente del documento.",
    buttonName: 'Siguiente'
  },
  {
    title: 'Termina y revisa tu ejercicio',
    description: "Cuando finalices tu ejercicio haz clic en “Enviar y revisar” y tu LUP será evaluada. Sabrás de inmediato si está correcta o si fuera necesario modificar algún detalle. ¡Y listo! Ya sabes cómo crear tu LUP",
    buttonName: '¡Comencemos!'
  },
]

export const itemsTutorialPoev: iItemTutorial[] = [
  {
    title: 'Hoy aprenderás a crear un algoritmo.',
    description: "Primero, deberás ingresar tu nombre",
    buttonName: 'Continuar'
  },
  {
    title: 'Ahora observa esta galería de elementos',
    description: "Algunos de ellos son pasos que faltan en la estructura. Otros son distractores",
    buttonName: 'Continuar'
  },
  {
    title: 'Selecciona y arrastra',
    description: "Deberás elegir los que consideres correctos y arrastrarlos hasta el lugar que les corresponda en la estructura",
    buttonName: 'Continuar'
  },
  {
    title: 'Envía',
    description: "Cuando la estructura esté completa, revísala y luego haz clic en enviar",
    buttonName: '¡Comenzar!'
  },
] 
