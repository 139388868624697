import useAppContext from '../../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'
// import { useEffect, useState } from 'react'
// import interactjs from 'interactjs'
// import ImagenLoader from '../../../components/Image'
// import { styled } from 'baseui'

// const ContainerImage = styled('div', props => ({
//   flex: '1',
//   overflowY: 'auto',
// }))

// const BoxImage = styled('div', props => ({

//   display: 'flex',
//   alignItems: 'center',
//   cursor: 'pointer',
//   backgroundColor: '#fff',
//   padding: '10px',
//   borderRadius: '.25em',
//   maxHeight: '200px',
//   width : 'max-content',
//   margin : '10px auto'
// }))

function ImagesGame() {
  const { dataGame , setDataGame , setActivePanel } = useAppContext()

  const handleChangeType = (e)=>{
    console.log(dataGame.stepActual)
    if(dataGame.stepActual < 0)
    {
      const target = e.target
      const value = target.value
      setDataGame({...dataGame, activoTipoLup: true, gametype: value})
      console.log(value, "valor")
      setActivePanel('Images' as any)
    }
  }

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
     <H5 margin={'20px 20px 5px'} color='#fff' $style={{textAlign : 'left'}}>
        Opciones
      </H5>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ParagraphSmall $style={{ color: '#fff', fontFamily: 'Roboto', fontWeight: 400, boxShadow: 'none' }}>
            Selecciona un tipo de LUP
        </ParagraphSmall>
      </div>
          <div>
            <div>
               <input style={{display:'none'}} onChange={handleChangeType} id="operacion" type='radio' name="tipo" value="operacion" />
               <label style={{display:'block', textAlign: 'center', margin: '0 20px 10px', border: '1px solid white', background: 'white', padding: '10px 0', borderRadius:'8px', cursor: 'pointer', fontWeight: 'bold'}}  htmlFor="operacion">Operación</label>
            </div>
            <div>
               <input style={{display:'none'}} onChange={handleChangeType} id="calidad" type='radio' name="tipo" value="calidad" />
               <label style={{display:'block', textAlign: 'center', margin: '0 20px 10px', border: '1px solid white', background: 'white', padding: '10px 0', borderRadius:'8px', cursor: 'pointer', fontWeight: 'bold'}} htmlFor="calidad">Calidad</label>
            </div>
            <div>
               <input style={{display:'none'}} onChange={handleChangeType} id="mantencion" type='radio' name="tipo" value="mantencion" />
               <label style={{display:'block', textAlign: 'center', margin: '0 20px 10px', border: '1px solid white', background: 'white', padding: '10px 0', borderRadius:'8px', cursor: 'pointer', fontWeight: 'bold'}} htmlFor="mantencion">Mantención</label>
            </div>
            <div>
               <input style={{display:'none'}} onChange={handleChangeType} id="seguridad" type='radio' name="tipo" value="seguridad" />
               <label style={{display:'block', textAlign: 'center', margin: '0 20px 10px', border: '1px solid white', background: 'white', padding: '10px 0', borderRadius:'8px', cursor: 'pointer', fontWeight: 'bold'}} htmlFor="seguridad">Seguridad</label>
            </div>
          </div>
    </div>
  )
}

export default ImagesGame