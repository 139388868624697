import { styled } from "baseui"
import { H4 } from "baseui/typography"
import Button  from "../../Common/Buttons"

const ContainerBottom = styled('div', props => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '5px',
  display: 'flex',
}))

const ElementBottom = styled('div', props => ({
  height: '100%',
  width: '25%',
}))

export const ButtonElements = () => (
  <ContainerBottom>
    <ElementBottom style={{ backgroundColor: '#F5BD00' }} />
    <ElementBottom style={{ backgroundColor: '#0091CE' }} />
    <ElementBottom style={{ backgroundColor: '#000000' }} />
    <ElementBottom style={{ backgroundColor: '#EB0F00' }} />
  </ContainerBottom>
)

export const ButtonStep = ({action = () => {}, alignButton = 'center', name= 'Siguiente'} : {action : () => void, alignButton?: 'center' | 'left' | 'right', name : string}) => (
  <div style={{ textAlign: alignButton }}>
    <Button.ButtonNormal
      action={action}
      textButton={name}
      type="secondary"
      children=''
      styles={{
        marginTop: '5px',
        marginBottom: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '30px',
        paddingLeft: '30px',
      }}
    />
  </div>
)

export const Title = ({alignText = 'center', color = 'primaryA', text  = ''} : {alignText?: 'center' | 'left' | 'right', color ?: string, text : string}) => {
  return (
    <H4 margin={'5px 0px'} color={color} $style={{textAlign : alignText}}>
      {text}
    </H4>
  )
}