import useAppContext from '../../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'

import { ContainerMain, ControladorComponent } from './components/DataUser'
import { iUserData } from '@/contexts/AppContext'

import { useForm } from 'react-hook-form'
// import { emailRegex } from '../../../utils'
// import { DatePicker } from 'baseui/datepicker'
// import { es } from 'date-fns/locale'

function DatosUser() {
  const {  dataUser, setDataUser } = useAppContext()

  const { control, getValues } = useForm<iUserData>({
    defaultValues: dataUser,
  })

  const setDataForm = async (key: keyof iUserData, value: string | boolean) => {
    setDataUser(data => ({
      ...data,
      [key]: value,
    }))
  }

  return (
    <ContainerMain>
      <H5 margin={'20px 20px 5px'} color="#fff" $style={{ textAlign: 'left' }}>
        {/* {dataGame?.nameGame} */}
      </H5>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <h3 style={{textAlign:"center",color:"#fff",fontWeight: '500 !important'}}>¿A quién no le ha pasado?</h3>
        <p style={{textAlign:"center",color:"#fff"}}>
          Completa este algoritmo para hacer funcionar tu impresora.
        </p>
        <ParagraphSmall margin={'5px 0px 15px'} color={'#fff'}>
          Escribe aquí tus datos :
        </ParagraphSmall>
        <form
          onChange={({ target }) => {
            const name = (target as any)?.name,
              value = (target as any)?.value
            if (name && value) {
              setDataForm(name, value)
              console.log(getValues(), "valores")
              const filtrado = Object.values(getValues()).filter(item => item === '')
              console.log(filtrado, "filtradooooooo")
              // if (filtrado.length === 0) {
                setDataForm('isComplete', true)
              // }
            }
          }}
        >
          <ControladorComponent
            label="Nombre"
            control={control}
            name="fullname"
            maxLength={50}
            placeholder="Ingrese su nombre"
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
          />


        </form>
      </div>
    </ContainerMain>
  )
}

export default DatosUser
