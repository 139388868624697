import useAppContext from '../../hooks/useAppContext'
import { styled, useStyletron } from 'baseui'
import { Modal, ModalBody } from 'baseui/modal'
import { H2, ParagraphMedium } from 'baseui/typography'
import { Icons } from '../../assets/Icons'
import {Button} from 'baseui/button';

import { ButtonElements } from '../Tutorial/components'

const ContainerButtons = styled('div', () => ({
  display: 'flex',
  justifyContent : 'space-around'
}))

function ModalIncorrect() {
  const { incorrectTask, setIncorrectTask, dataGame } = useAppContext()

  const [css, theme] = useStyletron()

  const closeModal = () => setIncorrectTask(false)

  const reload = () => {
    if (dataGame.name === 'POEV' || dataGame.name === 'LUP' || dataGame.name === 'algoritmo') {
      const elements = document.querySelectorAll('.drag-drop')
      elements.forEach((ele: HTMLElement) => {
        // reseteo
        ele.style.position = 'relative'
        ele.style.top = 'auto'
        ele.style.left = 'auto'
        ele.style.zIndex = 'initial'
        ele.removeAttribute('isNew')
      })
      closeModal()
    }
  }

  return (
    <Modal
      overrides={{
        Dialog: { style: { backgroundColor: '#F5F5F5',} },
        Close: { style: { color: '#0091CE' } },
        Root: { style: { zIndex: 99 } },
      }}
      isOpen={incorrectTask}
      role="dialog"
      unstable_ModalBackdropScroll
      onClose={tipo => {
        if (tipo.closeSource === 'closeButton') {
          closeModal()
        }
      }}
    >
      <ModalBody>
        <div style={{ padding: '40px 10px' }}>
          <div style={{ textAlign: 'center' }}>
            <Icons.Error size={122} />
            <H2
              className={css({
                color: theme.colors.primaryA,
              })}
            >
              ¡Ups! Algo salió mal
            </H2>
            <ParagraphMedium>
              Vuelve a revisar tus pasos. Puede haber un problema con la selección de los elementos o con su orden en el documento
            </ParagraphMedium>
            <ContainerButtons>
              <Button  kind='secondary' $style={{width : '170px'}} onClick={closeModal}>
                Modificar
              </Button>
              <Button $style={{width : '170px'}} onClick={reload}>
              Volver a empezar
              </Button>
              <ButtonElements />
            </ContainerButtons>
          </div>
        </div>
      </ModalBody> 
    </Modal>
  )
}

export default ModalIncorrect
