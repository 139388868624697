import { Button, KIND } from 'baseui/button';
import { CSSProperties } from 'react';

interface iButton {
  textButton: string,
  action?: () => void,
  type?: KIND[keyof KIND],
  styles: CSSProperties,
  typeButton?: 'submit' | 'reset' | 'button',
  isLoader?: boolean,
  children: any,
}

export const ButtonNormal = ({ children, textButton = '', action = () => { }, type = KIND.primary, styles, typeButton = 'button', isLoader = false }: iButton) => {
  return (
    <Button isLoading={isLoader} $style={{ ...styles, fontFamily: 'Roboto' }} type={typeButton} kind={type} onClick={action}>{textButton || children}</Button>
  )
}