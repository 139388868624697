import useAppContext from '../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'
import {
  ContainerData,
  ContainerMiddle,
  ContainerPoev,
  ItemP,
  ContainerParents,
  ContainerButton,
} from './index.components'
import { useEffect, useState } from 'react'
import interactjs from 'interactjs'
import { Button } from 'baseui/button'
import { PanelType } from '../../constants/app-options'
import { toaster } from 'baseui/toast'
import services from '../../services/games'
import domtoimage from 'dom-to-image';

function Poev() {
  const {
    dataUser,
    dataGame,
    activePanel,
    setActivePanel,
    setIncorrectTask,
    setCompletTast,
    setImage64,
    stepActive
  } = useAppContext()

  const [listStep, setList] = useState([])

  const [carga, setCarga] = useState(false)

  const setBoxSteps = (items: number) => {
    // para obteners las filas, multiplos de 4 ya que son 4 columnas
    /* const columnas = widthWindows > 1300 ? 4 : widthWindows > 768 ? 3 : 2
    const filas = items % columnas === 0 ? items / columnas : Math.floor(items / columnas) + 1 */
    const listTemp = []
    for (let index = 0; index < items; index++) {
      interactjs(`.dropzone${index}.move`).dropzone({
        accept: null,
        overlap: 1,
        ondrop: function (event) {
          // cuando está en el recuardo sin hacer drag
          const elementDrag: HTMLElement = event.target
          elementDrag.classList.remove('activeDrag')
          // debemos saber si ya existe un elemento mas en esta posicion
        },
        ondragleave: function (event) {
          // cuando sale del recuadro
          const elementDrag: HTMLElement = event.target,
            elementChildren: HTMLElement = event.relatedTarget
          elementDrag.classList.remove('activeDrag')
          elementChildren.removeAttribute('position')
          elementChildren.removeAttribute('parent')
          const elements = document.querySelectorAll(`div[position="${index + 1}"]`)
          if (elements.length > 1) {
            elementDrag.classList.add('more')
            elementChildren.classList.add('filter')
          } else {
            elementDrag.classList.remove('more')
            elementChildren.classList.remove('filter')
          }
        },
        ondragenter: function (event) {
          // cuando ingresa al recuadro
          const elementDrag: HTMLElement = event.target,
            elementChildren: HTMLElement = event.relatedTarget;

          elementDrag.classList.add('activeDrag')
          elementChildren.setAttribute('position', (index + 1).toString())
          elementChildren.setAttribute('parent', `.dropzone${index}`)
          elementDrag.setAttribute('isActive', 'true')
          elementDrag.classList.remove('border-error')
          const elements = document.querySelectorAll(`div[position="${index + 1}"]`)
          if (elements.length > 1) {
            elementDrag.classList.add('more')
            elementChildren.classList.add('filter')
          } else {
            elementDrag.classList.remove('more')
            elementChildren.classList.remove('filter')
          }
        },
      })
      listTemp.push(index)
    }
    setList(listTemp)
  }

  useEffect(() => {
    if (dataGame.numberStepGame) {
      setBoxSteps(dataGame.numberStepGame)
    }
  }, [dataGame])

  const nextStep = async () => {
    if (stepActive === 4) {
      return
    }
    if (activePanel === 'DataUser') {
      if (dataUser.isComplete) {
        setActivePanel('Images' as PanelType)
      } else {
        console.log(dataUser)
        toaster.negative('Falta tu información personal', {})
      }
      return
    }
    setCarga(true)

    const items = document.querySelectorAll('.drag-drop'),
      mapeo = []
    items.forEach(item => {
      mapeo.push({
        id: item.getAttribute('fb-id'),
        position: parseInt(item.getAttribute('position') || '0'),
      })
    })


    let sendMapeo = mapeo.filter(mape => mape.position !== 0)

    const { validate, poevConteoMalas } = await services.validateGame(sendMapeo, '')

    if (!validate) {
      setIncorrectTask(true)
      setCarga(false)

      if (poevConteoMalas.length > 0) {
        poevConteoMalas.forEach(el => {
          document.querySelector(`.dropzone[data-key="${el.position}"]`).classList.add("border-error")

        })

      }

      return false
    }
    console.log("es un error")

    //items que tengas dropzone (esto descomentar)
    const itemDropZone = document.querySelectorAll('.drag-drop[parent]')

    itemDropZone.forEach((ele: HTMLElement) => {
      const parent = document.querySelector(ele.getAttribute('parent'))
      console.log("parent",parent);
      const clonImage = ele.querySelector('img')
      clonImage.classList.add('activeImageSC')
      parent.appendChild(clonImage)
      ele.style.position = 'relative'
      ele.style.top = 'auto'
      ele.style.left = 'auto'
      ele.style.zIndex = 'initial'
      ele.removeAttribute('isNew')
    })


    // ==============================================================


    // const canvas = await html2canvas(document.querySelector('.parent2'), {
    //   allowTaint: true,
    //   useCORS: true,
    // })
    // const croppedCanvas = document.createElement('canvas')
    // const croppedCanvasContext = croppedCanvas.getContext('2d')
    // // init data
    // const cropPositionTop = 0
    // const cropPositionLeft = 0
    // const cropWidth = canvas.width
    // const cropHeight = canvas.height
    // croppedCanvas.width = cropWidth
    // croppedCanvas.height = cropHeight
    // croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)
    // const base64Image = croppedCanvas.toDataURL('image/png', 1)
    // await services.saveData(base64Image, dataUser.email, dataUser.fullname, dataGame.id, dataGame.name);

    // ==============================================================

    // esto descomnetar
    const node = document.querySelector('.parent2');
    generateImages()
    const image = await domtoimage.toPng(node)

    console.log(dataUser, "dataUser");

    await services.saveData(image, dataUser.email || "", dataUser.fullname, dataGame.id, dataGame.name);
    setCarga(false)
    setImage64(image)
    setCompletTast(true)
  }



  const generateImages = () => {
    document.querySelectorAll(".drag-drop").forEach((element: any) => {
      let isNew: any = element.getAttribute("isnew")
      let position = element.getAttribute("position")

      if (isNew === "true") {

        document.querySelectorAll(".dropzone").forEach(dropzone => {

          let key: any = dropzone.getAttribute("data-key");

          if (key === position) {
            dropzone.querySelector("div").style.display = "none"
            element.style.position = "relative"
            element.style.top = 0
            element.style.left = 0
            element.style.right = 0
            element.style.bottom = 0
            dropzone.appendChild(element)
          }

        })
      }

    })
  }

  const renderTextArrow = (key) => {

    switch(key){
      case 2:
        return(<p style={{textAlign:"center",marginBottom:"10px", color:"#fff",fontWeight:"500"}}>Conectar a la<br />corriente</p>)
      case 3:
        return(<p style={{textAlign:"center",marginBottom:"10px", color:"#fff",fontWeight:"500"}}>¿Está conectada al<br /> computador?</p>)
      case 8:
        return(<p style={{textAlign:"center",marginBottom:"10px", color:"#fff",fontWeight:"500"}}>Enviar al servicio<br />técnico</p>)
      case 9:
        return(<p style={{textAlign:"center",marginBottom:"10px", color:"#fff",fontWeight:"500"}}>Utilizar impresora</p>)
    }

  }

  const renderArrows = (key) => {

    if (key === 1 || key === 3 || key === 5 || key ===7){

      if(key === 3){
        return (
          <>
            <p style={{textAlign:"center",marginBottom:"10px", color:"#fff",fontWeight:"500"}}>¿Está conectada al<br /> computador?</p>
            <div className="flecha-derecha">
              &#x2192;
            </div>
            <div className="flecha-abajo">
              &#x2193;
            </div>
          </>
        )
      }else{

        return (
          <>
            <div className="flecha-derecha">
              &#x2192;
            </div>
            <div className="flecha-abajo">
              &#x2193;
            </div>
          </>
        )

      }

    }else{
      return(
      <>
        {
          renderTextArrow(key)
        }
      </>)
    }
  }

  return (
    <ContainerPoev>
      <div style={{ minWidth: '98.5%' }}>
        <ContainerMiddle style={{ boxShadow: activePanel === 'DataUser' ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : 'none' }} className="parent2" id="parent2">
          <H5
            paddingBottom={'10px'}
            margin={0}
            $style={{ textAlign: 'center', borderBottom: '1px solid #dddddd' }}
            color="rgb(0, 145, 206)"
          >
            {dataGame.nameGame}
          </H5>
          <ContainerData>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Nombre
              </ParagraphSmall>
              <ItemP active={!!dataUser.fullname} title={dataUser?.fullname} />
            </div>
            {/* <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Correo
              </ParagraphSmall>
              <ItemP active={!!dataUser.email} title={dataUser?.email} />
            </div>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Cargo
              </ParagraphSmall>
              <ItemP active={!!dataUser.post} title={dataUser?.post} />
            </div>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Empresa
              </ParagraphSmall>
              <ItemP active={!!dataUser.company} title={dataUser?.company} />
            </div>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Planta
              </ParagraphSmall>
              <ItemP active={!!dataUser.plant} title={dataUser?.plant} />
            </div>
            <div>
              <ParagraphSmall
                color="primary"
                $style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                marginBottom={'4px'}
              >
                Fecha
              </ParagraphSmall>
              <ItemP active={!!dataUser.date} title={dataUser?.date} />
            </div> */}
          </ContainerData>
          <ContainerParents>
            {listStep.map((item, key) => (
              <div key={`drop${key}`} 
                className={
                  ((key+1 === 2 ) || (key+1 === 3) || (key+1 === 8) || (key+1 === 9)) 
                  ? 
                  `dropzone dropzone${key}`
                  : 
                  `dropzone dropzone${key} move`
                } 
                data-key={key + 1}>
                
                {renderArrows(key + 1)}

              </div>
            ))}
          </ContainerParents>
        </ContainerMiddle>
      </div>
      <div style={{ width: '12%', height: '100%' }}>
        <ContainerButton style={{ zIndex: stepActive === 4 ? '9999' : 1 }}>
          <Button $style={{ width: '120px' }} isLoading={carga} onClick={nextStep}>
            {activePanel === 'DataUser' ? 'Siguiente' : 'Enviar y revisar'}
          </Button>
        </ContainerButton>
      </div>
    </ContainerPoev >
  )
}

export default Poev
