import { styled } from 'baseui'

import { ParagraphMedium } from 'baseui/typography'

import { ButtonElements, ButtonStep, Title } from './components'

import { iItemTutorial, itemsTutorialLup, itemsTutorialPoev } from '../../constants/data-games'
import { useEffect, useState } from 'react'
import useAppContext from '../../hooks/useAppContext'

import { Delete } from 'baseui/icon'
 
const ContainerTutorial = styled('div', () => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ContainerStep1 = styled('div', props => ({
  backgroundColor: '#fff',
  width: '300px',
  borderRadius: props.$theme.borders.radius400,
  padding: '50px 30px',
  position: 'relative',
  overflow: 'hidden',
}))

const ContainerStep2 = styled('div', props => ({
  backgroundColor: '#fff',
  width: '350px',
  padding: '50px 20px',
  overflow: 'hidden',
  position: 'absolute',
  top: '80%',
  right: 0,
  transform: 'translateY(-80%)',
  borderTopLeftRadius: props.$theme.borders.radius400,
  borderBottomLeftRadius: props.$theme.borders.radius400,
}))

function Tutorial({ step = 1, nextStep = () => {}, skipTutorial = () => {} }: { step: number; nextStep: () => void, skipTutorial : () => void }) {
  const { dataGame } = useAppContext()

  const [dataTutorial, setDataTutorial] = useState<iItemTutorial[]>([])
 
  useEffect(() => {
    if (dataGame.name === 'LUP') {
      setDataTutorial(itemsTutorialLup)
    } else if (dataGame.name === 'algoritmo') {
      console.log("si tiene tutorial ",itemsTutorialLup)
      setDataTutorial(itemsTutorialPoev)
    }
  }, [dataGame])
 
  return (
    <ContainerTutorial>
      {step === 1 ? (
        <ContainerStep2>
          <div style={{position : 'absolute', top : '10px', right :'10px', cursor : 'pointer'}} onClick={skipTutorial}>
            <Delete color="primary" size={40} />
          </div>          
          <Title text="1" alignText="right" />
          <Title text={dataTutorial[0]?.title} alignText="right" />
          <hr style={{ width: '50%', borderColor: '#ffffff5e', margin: '20px auto' }} />
          <ParagraphMedium $style={{ textAlign: 'right' }}>{dataTutorial[0]?.description}</ParagraphMedium>
          <ButtonStep action={nextStep} name={dataTutorial[0]?.buttonName} alignButton="right" />
          <ButtonElements />
        </ContainerStep2>
      ) : null}
      {step === 2 ? (
        <ContainerStep2>
          <div style={{position : 'absolute', top : '10px', left :'10px', cursor : 'pointer'}} onClick={skipTutorial}>
            <Delete color="primary" size={40} />
          </div>
          <Title text="2" alignText="right" />
          <Title text={dataTutorial[1]?.title} alignText="right" />
          <hr style={{ width: '50%', borderColor: '#ffffff5e', margin: '20px auto' }} />
          <ParagraphMedium $style={{ textAlign: 'right' }}>{dataTutorial[1]?.description}</ParagraphMedium>
          <ButtonStep action={nextStep} name={dataTutorial[1]?.buttonName} alignButton="right" />
          <ButtonElements />
        </ContainerStep2>
      ) : null}
      {step === 3 ? (
        <ContainerStep2>
          <div style={{position : 'absolute', top : '10px', left :'10px', cursor : 'pointer'}} onClick={skipTutorial}>
            <Delete color="primary" size={40} />
          </div>
          <Title text="3" alignText="right" />
          <Title text={dataTutorial[2]?.title} alignText="right" />
          <hr style={{ width: '50%', borderColor: '#ffffff5e', margin: '20px auto' }} />
          <ParagraphMedium $style={{ textAlign: 'right' }}>{dataTutorial[2]?.description}</ParagraphMedium>
          <ButtonStep action={nextStep} name={dataTutorial[2]?.buttonName} alignButton="right" />
          <ButtonElements />
        </ContainerStep2>
      ) : null}      
      {step === 4 ? (
        <ContainerStep2>
          <div style={{position : 'absolute', top : '10px', left :'10px', cursor : 'pointer'}} onClick={skipTutorial}>
            <Delete color="primary" size={40} />
          </div>
          <Title text="4" alignText="right" />
          <Title text={dataTutorial[3]?.title} alignText="right" />
          <hr style={{ width: '50%', borderColor: '#ffffff5e', margin: '20px auto' }} />
          <ParagraphMedium $style={{ textAlign: 'right' }}>{dataTutorial[3]?.description}</ParagraphMedium>
          <ButtonStep action={nextStep} alignButton="right" name={dataTutorial[3]?.buttonName} />
          <ButtonElements />
        </ContainerStep2>
      ) : null}
      {step === 5 ? (
        <ContainerStep1>
          <Title text={dataTutorial[4]?.title} />
          <hr style={{ width: '50%', borderColor: '#ffffff5e', margin: '20px auto' }} />
          <ParagraphMedium $style={{ textAlign: 'center' }}>{dataTutorial[4]?.description}</ParagraphMedium>
          <ButtonStep action={nextStep} name={dataTutorial[4]?.buttonName} />
          <ButtonElements />
        </ContainerStep1>
      ) : null}
    </ContainerTutorial>
  )
} 
 
export default Tutorial
