
import { StyledSpinnerNext } from 'baseui/spinner'
import { ImgHTMLAttributes, useEffect, useState } from 'react'
import useAppContext from '../hooks/useAppContext'
interface iImage extends ImgHTMLAttributes<HTMLImageElement>{
  autoHeight?:boolean
}

function ImagenLoader(props: iImage) {
  const [carga, setCarga] = useState(true)
  const {
    dataGame,
  } = useAppContext()

  useEffect(()=>{
    console.log(dataGame, "datos game")
  },[dataGame])

  return (
    <>
      {carga && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <StyledSpinnerNext />
        </div>
      )}
      {
      <div
        style={{
          height: props.autoHeight ? 'auto' : '100%',
          position: 'relative',
          display: carga ? 'none':'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width : '100%'
        }}
      >

          <img
            alt="imagenes"
            {...props}
            onLoad={e => {
              setCarga(false)
            }}
            onError={e => {
              console.log('object')
              setCarga(false)
            }}
          />

      </div>
      }

    </>
  )
}

export default ImagenLoader
