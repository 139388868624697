import LogoPng from '../../assets/logo-collahuasi2.png'
import { BlockProps } from 'baseui/block'
import { ParagraphMedium } from 'baseui/typography'
import { Icons } from '../../assets/Icons'

import { Container, ContainerBox, LogoContainer } from './Components'

import useHover from '../../hooks/useHover'
import { $StyleProp } from 'styletron-react'
import useAppContext from '../../hooks/useAppContext'

import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
interface iProps {
  openTutorial: () => void
}

function NavbarEditor({ openTutorial = () => { } }: iProps) {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()
  const [hoverRef2, isHovered2] = useHover<HTMLDivElement>()
  const [hoverRef3, isHovered3] = useHover<HTMLDivElement>()
  const { setDataGame, dataGame, setActivePanel, setNameCard, setDataCardRed, setItemsCard, itemsCard, dataUser } = useAppContext()

  const styleP: $StyleProp<BlockProps> = {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '10px',
  }

  const resetGame = () => {
    // const dataOptionGame = (data)=> ({
    //   'ALGORITMO': {
    //     company: '',
    //     date: data.date,
    //      email: '',
    //      fullname: '',
    //      plant: '',
    //      post: '',
    //      isComplete: false,
    //    },
    //   'POEV': {
    //    company: '',
    //    date: data.date,
    //     email: '',
    //     fullname: '',
    //     plant: '',
    //     post: '',
    //     isComplete: false,
    //   },
    //   'LUP':{
    //            // company: '',
    //            date: data.date,
    //            // email: '',
    //             fullname: '',
    //             plant: '',
    //             post: '',
    //             isComplete: false,
    //   }
    // })
    if (dataGame.name === 'POEV' || dataGame.name === 'LUP' || dataGame.name === 'algoritmo') {
      const elements = document.querySelectorAll('.drag-drop');
      setActivePanel('DataUser' as any)
      // setDataUser((data) => (dataOptionGame(data)[dataGame.name]))
      setDataGame({...dataGame, activoTipoLup: false, gametype:''})
      elements.forEach((ele: HTMLElement) => {
        // reseteo
        ele.style.position = 'relative';
        ele.style.top = 'auto';
        ele.style.left = 'auto'
        ele.style.zIndex = 'initial'
        ele.removeAttribute('isNew')
      })
    } else {
      setNameCard('')
      setDataCardRed(() => ({
        date: '',
        fullname: '',
        email: '',
        line: '',
        numberCard: '',
        observations: '',
        team: '',
      }))
      const resetItems = itemsCard.map((value) => ({ ...value, active: false }))
      setItemsCard(resetItems)
    }
  }

  const initTutorial = () => {
    //  setActivePanel('DataUser' as any )
    openTutorial();
  }

  const changeTipoLup = ()=>{
    if(dataUser.isComplete){
      const elements = document.querySelectorAll('.drag-drop');
      setActivePanel('TipoLup' as any)
      setDataGame({...dataGame, activoTipoLup: false, gametype: ''})
      elements.forEach((ele: HTMLElement) => {
        // reseteo
        ele.style.position = 'relative';
        ele.style.top = 'auto';
        ele.style.left = 'auto'
        ele.style.zIndex = 'initial'
        ele.removeAttribute('isNew')
      })
      return
    }else{
      return
    }
  }

  return (
    <Container>
      <Grid align={ALIGNMENT.center} gridGutters={0} gridColumns={[12, 12, 12]} gridMargins={[8, 16, 36]}>
        <Cell span={[1, 2, 1]}>
          <LogoContainer>
            <img alt="logo" style={{ maxWidth: '100%', maxHeight: '90px', objectFit: 'contain' }} src={LogoPng} />
          </LogoContainer>
        </Cell>
        <Cell skip={[0, 0, 1]} span={[1, 8, 8]}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <ContainerBox
              ref={hoverRef}
              onClick={initTutorial}
            >
              <Icons.Record size={20} color={isHovered ? '#fff' : '#EB0F00'} />
              <ParagraphMedium $style={{
                ...styleP, color: isHovered ? '#fff' : '#575757', '@media (max-width : 1136px)': {
                  fontSize: '14px'
                }
              }}>
                Revisar ejemplo
              </ParagraphMedium>
            </ContainerBox>

            <ContainerBox
              ref={hoverRef2}
              onClick={resetGame}
            >
              <Icons.Reload size={24} color={isHovered2 ? '#fff' : '#0091CE'} />
              <ParagraphMedium $style={{
                ...styleP, color: isHovered2 ? '#fff' : '#575757', '@media (max-width : 1136px)': {
                  fontSize: '14px'
                }
              }}>
                Volver a empezar
              </ParagraphMedium>
            </ContainerBox>

            {dataGame.name === 'LUP' && 
              <ContainerBox
                ref={hoverRef3}
                onClick={changeTipoLup}
              >
                <Icons.TipoLup size={24} color={isHovered3 ? '#fff' : '#0091CE'} />
                <ParagraphMedium $style={{
                  ...styleP, color: isHovered3 ? '#fff' : '#575757', '@media (max-width : 1136px)': {
                    fontSize: '14px'
                  }
                }}>
                  Cambiar Tipo LUP
                </ParagraphMedium>
              </ContainerBox>            
            }


          </div>
        </Cell>
        <Cell skip={[0, 0, 1]} span={[1, 2, 1]}>
          <LogoContainer>
            {/* <img alt="logo" style={{ maxWidth: '100%', maxHeight: '70px', objectFit: 'contain' }} src={LogoPng} /> */}
          </LogoContainer>
        </Cell>
      </Grid>
    </Container>
  )
}

export default NavbarEditor
