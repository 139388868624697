import { iDataGame } from '../constants/data-games'
import { iCompany } from '../constants/data-companies'

import { getData, getImage, saveDataResults, sendImage } from './actionsFB'
import lodash from 'lodash'

interface iItemImage {
  id: string
  position: number
}
 
interface validateGame {
  validate: boolean
  lupConteoMalas: any
  poevConteoMalas: any
}

class GamesService {
  data: iDataGame = null
  companies: iCompany[]

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  async getDataGame(id: string): Promise<iDataGame | null> {
    const data = await getData<iDataGame>('id', id, 'games')
    if (!data) return null
    // obtengo URL de la imagen de referencia del juego

    // obtengo la URL de las imagenes del juego
    /* const imagenes : iImages[] = []
    
    for (let index = 0; index < data.images.length; index++) {
      const element = data.images[index];
      const getUrlImage = await getImage(element.url)
      imagenes.push({...element, url : getUrlImage})
    } */
    console.log(data,"jeyjey")

    if (data.imageRef) {
      const imagen = await getImage(data.imageRef)
      console.log("SALI IMAGEN")
      // para revertir lista y este desorneada
      const images = lodash.shuffle(data.images)
      const dataNueva = { ...data, imageRef: imagen, images }
      this.data = dataNueva
      return dataNueva
    } else {
      const images = lodash.shuffle(data.images)
      this.data = { ...data, images }
      return data
    }
  }



  validateGame(items: iItemImage[], id: string): Promise<validateGame> {
    return new Promise((res, reject) => {
      console.log(this.data.images, "validateGame")
      if (!this.data) return false
      setTimeout(() => {
        // la idea aqui es simular una api rest
        const imagesforGame = this.data.images
        if (!imagesforGame) {
          reject('Juego no encontrado')
        }
        // let conteo = 0
        let lupConteoMalas = []
        let poevConteoMalas = []

        console.log("items",items)

        if(items.length === 5) { 
          let siguiente =  {
            position : null,
            adecuada:null
          }
          for (let index = 0; index < items.length; index++) {

            const element = items[index]
            console.log(element, "el elemento")
            if (id === "LUP") { 
              let posicionAdecuada =  parseInt(element.id.charAt(element.id.length-1))

              if((element.position % 2) > 0 && (posicionAdecuada % 2) > 0){
                 // impar son los buenos
                siguiente = {
                  position : element.position + 1,
                  adecuada: posicionAdecuada + 1
                }
              }else{
                // par son los malos
                if(siguiente.position === element.position && posicionAdecuada === siguiente.adecuada){
                    // conteo++
                }else{
                  lupConteoMalas.push(element)
                }
              }
              console.log(lupConteoMalas, "malass")
              // if(posicionAdecuada === element.position){
              //   conteo++;
              // }else{
              //   lupConteoMalas.push(element)
              // }
              // if (element.id === "Image_03" || element.id === "Image_01" || element.id === "Image_06") {
  
              //   if (element.position === 1 || element.position === 3) {
              //     conteo++
              //   }
              //   else {
              //     lupConteoMalas.push(element)
              //   }
              // } else {
  
              //   if (element.position === 2 || element.position === 4) {
              //     conteo++
  
              //   }
              //   else {
              //     lupConteoMalas.push(element)
              //   }
  
              // }
  
            } else {
  
              const elementImage = imagesforGame.find(image => image.id === element.id)
  
              if (element.position === elementImage.position) {
  
                // conteo++
  
              }
  
              else {
                poevConteoMalas.push(element)
              }
  
            }
  
          }
          console.log("imagesforGame",imagesforGame);
          console.log("poevConteoMalas", poevConteoMalas)
          res({
            validate: poevConteoMalas.length === 0,
            lupConteoMalas,
            poevConteoMalas
          })
        }else{
          res({
            validate: false,
            lupConteoMalas,
            poevConteoMalas
          })
        }
      }, 100)
    })
  }

  async saveData(
    base: string,
    email: string,
    fullname: string,
    idGame: string,
    nameGame: string
  ): Promise<void> {
    const referenciaStorage = await sendImage(base)
    await saveDataResults({ email, fullname }, { id: idGame, name: nameGame }, referenciaStorage.ref.fullPath)
    const imageURL = await getImage(referenciaStorage.ref.fullPath)
    // send mail
    try {
      const data = new FormData();
      data.append('email', email || "")
      data.append('name', fullname || "")
      data.append('game', nameGame || "")
      data.append('image', imageURL || "")
      if(nameGame === 'POEV'){
        const responseEmail = await fetch(
          `https://labsjoinlife.cl/LTI/envio_email.php`, {
          method: 'POST',
          body: data
        }
        )
        console.log(responseEmail)
      }

    } catch (error) {
      console.log(error)
    }
  }

  async getCompanyByName(name: string): Promise<iCompany | null> {
    return await getData<iCompany>('name', name, 'companies')
  }
}

export default new GamesService()
