import { ItemsCardRed, PanelType } from '../constants/app-options'
import React, { createContext, useState, FC, SetStateAction } from 'react'

import { iDataGame } from '../constants/data-games'
import { iCompany } from '@/constants/data-companies'

type Template = any
export interface iUserData {
  fullname: string 
  email ?: string
  date : string
  company ?: string
  plant : string
  post : string
  /* Para facilitarme la validacion de datos */
  isComplete ?:boolean
}
export interface iItemsCard {
  name: string
  active: boolean
}
export interface iDataCard {
  fullname : string
  email : string
  numberCard : string
  line : string
  team : string
  date : string
  observations :string
}
export interface iDataTask {
  fullnames : string,
  correo : string,
  fecha : string,
  empresa : string,
  planta : string,
  cargo : string,
}
interface IAppContext {
  widthWindows : number | undefined,
  setWidthW : (value : number | undefined) => void,
  isMobile: boolean | undefined
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>
  templates: Template[]
  setTemplates: (templates: Template[]) => void
  activePanel: PanelType
  setActivePanel: (option: PanelType) => void
  // new fer
  dataUser: iUserData
  setDataUser: (data : SetStateAction<iUserData>) => void
  dataGame: iDataGame
  setDataGame: (date: iDataGame) => void
  activeWelcome: boolean
  setActiveWelcome: (value: boolean) => void
  dataCompany: iCompany
  setDataCompany: (data: iCompany) => void
  stepActive: number
  setActiveStep: (value: number) => void
  isCompleteTask: boolean
  setCompletTast: (value: boolean) => void
  isErrorCard: boolean
  setErrorCard: (value: boolean) => void
  nameCard: string
  setNameCard: (value: string) => void
  itemsCard: iItemsCard[]
  setItemsCard: (items: iItemsCard[]) => void
  dataCardRed : iDataCard
  setDataCardRed : (data : SetStateAction<iDataCard>) => void
  incorrectTask : boolean
  setIncorrectTask : (value : boolean) => void
  image64 : string
  setImage64 : (value : string) => void
  dataTask : iDataTask
  setDataTask : (value : iDataTask) => void
}

export const AppContext = createContext<IAppContext>({
  widthWindows : undefined,
  setWidthW : () => {},
  isMobile: false,
  setIsMobile: () => {},
  templates: [],
  setTemplates: () => {},
  activePanel: PanelType.TEMPLATES,
  setActivePanel: () => {},
  // new fer
  dataUser: {
   // company : '',
    date : '',
   // email: '',
    fullname: '',
    plant:'',
    post : ''
  },
  setDataUser: () => {},
  dataGame: {
    activoTipoLup:false,
    description: '',
    id: '',
    images: [],
    name: '',
    nameExtend: '',
    imageRef: '',
    color: '',
    buttonColor: '',
    numberStepGame: 1,
    numberStepTutorial: 1,
    descriptionInitial : '',
    stepActual: 1,
    gametype: '',
    multipleImages:{}
  },
  setDataGame: () => {},
  activeWelcome: true,
  setActiveWelcome: () => {},
  dataCompany: {
    logo: '',
    name: '',
    rut: '',
    years: 0,
  },
  setDataCompany: () => {},
  stepActive: 1,
  setActiveStep: () => {},
  isCompleteTask: false,
  setCompletTast: () => {},
  isErrorCard: false,
  setErrorCard: (value: boolean) => {},
  nameCard: '',
  setNameCard: (value: string) => {},
  itemsCard: [],
  setItemsCard: () => {},
  dataCardRed : {
    date : '',
    line :'',
    numberCard : '',
    observations : '',
    team :'',
    email : '',
    fullname : ''
  },
  setDataCardRed : () => {},
  incorrectTask : false,
  setIncorrectTask : (value : boolean) => {},
  image64 : "",
  setImage64 : (value : string) => {},
  dataTask :{
    cargo : '',
    correo : '',
    empresa : '',
    fecha : '',
    fullnames: '',
    planta : ''
  },
  setDataTask : (value : iDataTask)  => {}
})

export const AppProvider: FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(undefined)
  const [templates, setTemplates] = useState<Template[]>([])
  const [activePanel, setActivePanel] = useState<PanelType>(PanelType.TEMPLATES)
  const [dataUser, setDataUser] = useState<iUserData>({
   // company : '',
    date : '',
    //email : '',
    fullname:'',
    plant : '',
    post: ''
  })
  const [dataGame, setDataGame] = useState<iDataGame>({
    activoTipoLup:false,
    description: '',
    id: '',
    images: [],
    name: '',
    nameExtend: '',
    imageRef: '',
    color: '',
    buttonColor: '',
    numberStepGame: 1,
    numberStepTutorial: 1,
    descriptionInitial : '',
    stepActual: 1,
    gametype: '',
    multipleImages:{}
  }) 
  const [activeWelcome, setActiveWelcome] = useState(true)

  const [dataCompany, setDataCompany] = useState<iCompany>({
    logo: '',
    name: '',
    rut: '',
    years: 0,
  })

  const [stepActive, setActiveStep] = useState<number>(1)

  const [isCompleteTask, setCompletTast] = useState<boolean>(false)

  const [isErrorCard, setErrorCard] = useState<boolean>(false)

  const [nameCard, setNameCard] = useState<string>('')

  const [itemsCard, setItemsCard] = useState<iItemsCard[]>(ItemsCardRed)

  const [dataCardRed, setDataCardRed] = useState<iDataCard>({
    date : '',
    line :'',
    numberCard : '',
    observations : '',
    team :'',
    email : '',
    fullname : ''
  })
  
  const [incorrectTask, setIncorrectTask] = useState(false)

  const [image64, setImage64] = useState('')

  const [widthWindows, setWidthW] = useState(undefined);

  const [dataTask, setDataTask] = useState<iDataTask>({
    cargo : '',
    correo : '',
    empresa : '',
    fecha : '',
    fullnames: '',
    planta : ''
  })

  const context = {
    isMobile,
    setIsMobile,
    templates,
    setTemplates,
    activePanel,
    setActivePanel,
    dataUser,
    setDataUser,
    dataGame,
    setDataGame,
    activeWelcome,
    setActiveWelcome,
    dataCompany,
    setDataCompany,
    stepActive,
    setActiveStep,
    isCompleteTask,
    setCompletTast,
    isErrorCard,
    setErrorCard,
    nameCard,
    setNameCard,
    itemsCard,
    setItemsCard,
    dataCardRed,
    setDataCardRed,
    incorrectTask,
    setIncorrectTask,
    image64,
    setImage64,
    widthWindows,
    setWidthW,
    dataTask,
    setDataTask
  }
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
