import games from '../services/games'
import { useEffect, useState } from 'react'
import useAppContext from '../hooks/useAppContext'
import { useSearchParams } from 'react-router-dom'
import Components from '../components'
import { ExceptionCustom } from '../utils'
import { toaster, ToasterContainer } from 'baseui/toast'

interface iErrorDetails {
  name: string
  details: any[]
}

function App() {
  const {
    isMobile,
    setDataGame,
    setDataCompany,
    activeWelcome,
    stepActive,
    setActiveStep,
    setActivePanel,
    dataGame,
    isErrorCard,
    setErrorCard,
    isCompleteTask,
  } = useAppContext()
  const [searchParams] = useSearchParams({})
  const [isError, setError] = useState<boolean>(true)
  const [errorDetails, setErrorDetails] = useState<iErrorDetails>({ name: '', details: [] })
  const [paramsGame, setParams] = useState({
    id: '',
    name: '',
  })
  const [loaded, setLoaded] = useState<boolean>(false)
  const [activeTutorial, setOpenTutorial] = useState<boolean>(false)
  const [modalInfo, setActiveModal] = useState<boolean>(true)
  const [example, setExample] = useState<boolean>(false)

  useEffect(() => {
    console.log(dataGame, "active")
  }, [dataGame])

  useEffect(() => {
    const idGame = searchParams.get('id'),
      nameCompany = searchParams.get('name')
    setParams({
      id: idGame,
      name: nameCompany,
    })
  }, [searchParams])

  useEffect(() => {
    // get data
    const getDataGame = async (id: string, name: string) => {
      setLoaded(true)
      document.querySelector("footer").style.opacity = "1"
      try {
        console.log("paso por aqui")
        const data = await games.getDataGame(id)
        const dataCompany = await games.getCompanyByName(name)
        if (!data) throw new ExceptionCustom('Ocurrio un error en obtener la información del juego')
        if (!dataCompany) 
          throw new ExceptionCustom('Ocurrio un error en obtener la información de la compañia')
        setDataGame(data)
        setDataCompany(dataCompany)
        setError(false)
      } catch (error) {
        const err: ExceptionCustom = error
        setErrorDetails({ name: err.message, details: [] })
        setError(true)
      }
      setLoaded(false)
    }

    const { id, name } = paramsGame
    try {
      if (!id) {

        document.querySelector("footer").style.opacity = "0"
        throw new ExceptionCustom('Query param (id) not found.')
      }
      if (!name) {

        document.querySelector("footer").style.opacity = "0"
        throw new ExceptionCustom('Query param (name) not found.')
      }
      // quitamos o ocultamos el footer
      getDataGame(id, name)
    } catch (error) {
      const err: ExceptionCustom = error
      setErrorDetails({ name: err.message, details: [] })
      setLoaded(false)
      setError(true)
    }
  }, [paramsGame, setDataCompany, setDataGame])


  useEffect(() => {
    if (dataGame.name === 'Tarjetas') {
      setActivePanel('Cards' as any)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataGame])

  if (loaded) {
    return <Components.Loading isMain={true} />
  }

  if (isError) {
    return <Components.ErrorParams details={errorDetails.details} name={errorDetails.name} />
  }
 
  if (isMobile) {
    return <Components.Mobile />
  }

  if (activeWelcome) {
    return <Components.Welcome />
  }

  const openTutorial = (value: boolean) => {
    setOpenTutorial(value)
    if (value) {
      setActiveStep(1)
    }
  }

  const changeStep = (type: 'next' | 'back') => {

    console.log("cambiando tutorial")

    if (type === 'back') {
      if (stepActive > 1) {
        setActiveStep(stepActive - 1)
        setDataGame({...dataGame, stepActual: stepActive - 1})
      } else {
        toaster.negative('Ya no hay más pasos anteriores.', {})
      }
    } else if (type === 'next') {
      if(dataGame.name === 'LUP'){
        if (stepActive === 2 || ( dataGame.name === 'LUP' && stepActive === 4)) {
          setActivePanel('TipoLup' as any)
        }
        if(stepActive === 3 || ( dataGame.name === 'LUP' && stepActive === 4)){
          setActivePanel('Images' as any)
        }
      }else{
        if (stepActive === 1 ) {
          setActivePanel('Images' as any)
        }
      }

      if (stepActive < dataGame.numberStepTutorial) {
        setActiveStep(stepActive + 1)
        setDataGame({...dataGame, stepActual: stepActive + 1})
      } else {
        setActiveStep(-1)
        setDataGame({...dataGame, stepActual: - 1})
        openTutorial(false)
        setActivePanel('DataUser' as any)
        toaster.positive('Tutorial finalizado', {})
      }
    }
  }

  const skipTutorial = () => {
    setActiveStep(-1)
    openTutorial(false)
    setActivePanel('DataUser' as any)
    toaster.positive('Tutorial finalizado', {})
  }


  const handleTutorial = () => {
    setActiveModal(true)
    setExample(true)
  }

  const handleCloseInfoGame = (value) => {
    if (!example) {
      setActiveModal(value)
      openTutorial(true);
    } else {
      setActiveModal(value)
    }
  }




  return (
    <>
      <ToasterContainer placement="bottom" autoHideDuration={2000} />
      {activeTutorial && dataGame.name !== 'Tarjetas' ? (
        <Components.Tutorial
          step={stepActive}
          nextStep={() => changeStep('next')}
          skipTutorial={skipTutorial}
        />
      ) : null}
      {/* Solo tarjetas */}
      {isErrorCard && <Components.EleccionIncorrecta isActive={isErrorCard} closeModal={setErrorCard} />}
      <Components.InfoGame isActive={modalInfo} closeModal={(value) => handleCloseInfoGame(value)} />
      <Components.ModalIncorrect />
      <Components.Navbar
        openTutorial={handleTutorial}
      />
      {isCompleteTask && <div className="efect_blur"></div>}
      <Components.ModalComplete isActive={isCompleteTask} />
      <div style={{ flexGrow: 1, display: 'flex', backgroundColor: '#f5f5f5' }}>
        <Components.Panels />
        <div style={{ flex: 1, display: 'flex' }} data-test="kevin22">
          {dataGame.name === 'Tarjetas' ? <Components.Tarjetas /> : <Components.WorkTable />}
        </div>
      </div>
    </>
  )
}

export default App
